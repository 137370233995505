import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

export default function SEO({ title, description, blogPost }) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata;
  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {blogPost ? <meta property="og:type" content="article" /> : null}
      {blogPost ? (
        <meta property="article:published_time" content={blogPost.date} />
      ) : null}
      {blogPost ? (
        <meta property="article:author" content="https://github.com/remybach" />
      ) : null}
      {blogPost?.tags
        ? blogPost.tags.map((tag) => (
            <meta key={`og-tag-${tag}`} property="article:tag" content={tag} />
          ))
        : null}
      {blogPost ? null : <meta property="og:type" content="website" />}
    </Helmet>
  );
}

SEO.propTypes = {
  currentPage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  blogPost: PropTypes.object,
};
SEO.defaultProps = {
  currentPage: null,
  title: null,
  description: null,
  blogPost: null,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        twitterUsername
      }
    }
  }
`;
